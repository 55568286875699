import { useMediaQueryList } from "@hooks/use-media-query";
import { useEffect } from "react";
import { resetTableState, setTableType } from "@store/table/table-slice";
import { ProjectEvents, TableType } from "@utils/track-event/track-event-list";
import { useAppDispatch } from "@store/store-helper";
import { ProjectBulkButtonComponents } from "@components/common/faro-table/faro-table-types";
import { FaroTable } from "@components/common/faro-table/faro-table";
import { Box } from "@mui/material";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { MemberGroupProjectsHeaders } from "@components/table/member-group-projects/member-group-projects-table-utils";
import { getMemberGroupProjectsColumns } from "@components/table/member-group-projects/member-group-projects-columns";
import { useAppNavigation } from "@hooks/navigation/use-app-navigation";
import { useAppParams } from "@router/router-helper";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** The projects that is shown in the table */
  projects: SphereDashboardAPITypes.GroupDetailsProjectSummary[];

  /** Whether the data for showing a table is loading or not */
  isLoading: boolean;

  /** All the columns that we want to show. The order defines the order of the columns */
  requiredColumns: MemberGroupProjectsHeaders[];

  /** List of all the bulk actions and callbacks */
  bulkActionButtons?: ProjectBulkButtonComponents;
}

/**
 * Listing all the projects in a group that a member belongs to
 */
export function MemberGroupProjectsTable({
  projects,
  isLoading,
  requiredColumns,
  bulkActionButtons,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { isMedium, isScreenLgAndLarger } = useMediaQueryList();
  const { navigateToProjectDetail } = useAppNavigation();
  const { companyId } = useAppParams();
  const { trackEvent } = useTrackEvent();

  // Make sure the correct table type is set in the store and reset the bulk action state after unmounting
  useEffect(() => {
    dispatch(setTableType(TableType.memberGroupProjects));

    return () => {
      dispatch(resetTableState());
    };
  }, [dispatch]);

  return (
    <Box component="div" sx={{ pb: "24px" }}>
      <FaroTable
        entities={projects}
        uniqueIdKey={"id"}
        isLoading={isLoading}
        sx={{
          cursor: "pointer",
        }}
        columns={requiredColumns.map(
          (requiredColumn) =>
            getMemberGroupProjectsColumns({
              options: {
                isScreenLgOrLarger: isScreenLgAndLarger,
                isMedium,
              },
            })[requiredColumn]
        )}
        bulkActionButtons={bulkActionButtons}
        rowHeight={70}
        shouldHideCheckbox={true}
        shouldHideHeader={true}
        onRowClick={(id) => {
          trackEvent({
            name: ProjectEvents.openProjectDetails,
            props: {
              eventLocation: "user-profile-group-projects",
            },
          });
          if (companyId) {
            navigateToProjectDetail({
              companyId,
              projectId: id,
            });
          }
        }}
      />
    </Box>
  );
}
